import React from 'react'
import Navbar from '../components/Navbar'
import ShopList from '../components/ShopList'
import Footer2 from '../components/footer2'

function shop() {
  return (
    <>
      <Navbar/>
      <ShopList/>
      <Footer2/>
    </>
  )
}

export default shop
