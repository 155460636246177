import React from 'react'
import CartList from '../components/CartList'
import Navbar from '../components/Navbar'
import Footer2 from '../components/footer2'

function cart() {
  return (
    <>
      <Navbar/>
      <CartList/>
      <Footer2/>
    </>
  )
}

export default cart
 