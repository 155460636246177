import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

function contact() {
  return (
    <>
        <Navbar/>
        <Footer/>
    </>
  )
}

export default contact
