import React from 'react'
import Footer from '../components/footer2'
import Navbar from '../components/Navbar'
import Product from '../components/Product'

function product() {
  return (
    <>
        <Navbar/>
        <Product/>
        <Footer/>
    </>
  )
}

export default product
